.orders-container {
  margin-top: 12px;
}

.order-card {
  width: 100%;
  background-color: white;
  padding: 12px;
  margin-bottom: 10px;
}

.order-card__upper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.order-card__upper h4 {
  font-size: 16px;
  font-weight: 600;
}

.order-card__upper h5 {
  font-size: 18px;
  font-weight: 700;
  padding-left: 10px;
}

.order-card__lower {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.order-card__lower span {
  /* background-color: grey; */
  text-align: center;
  padding: 8px 15px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
}

.order-card__lower a {
  margin-left: auto;
  /* padding-right: 15px; */
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}

.order-card__lower a h4 {
  color: #333;
  font-weight: 600;
  padding-right: 3px;
}

.order-card__lower a .details-arrow-button {
  display: flex;
  align-items: center;
}

.pending {
  background-color: #ff9900;
}

.on-route {
  background-color: #50a8ff;
}

.declined {
  background-color: #b90504;
}

.accepted {
  background-color: #008000;
}
