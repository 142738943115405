@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  background-color: black;
  background-image: url("./assets/wavesBackgroundBlack.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

body {
  background-color: #f6f6f6;
  color: #2f2c2c;
  max-width: 450px;
  width: 100%;
  height: 100%;
  font-family: Montserrat;
}

button {
  font-family: inherit;
  font-size: 1em;
}

#root {
  height: 100%;
  overflow-y: auto;
}

#root::-webkit-scrollbar {
  display: none;
}

:root {
  --red: #b90504;
  --black: #222222;
  --darkgray: #333333;
  --lightgray: #f6f6f6;
}

*:focus {
  outline: none;
}

.logo-text {
  font-family: Montserrat;
  font-size: 25px;
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.logo-text span {
  font-size: 13px;
  font-weight: 100;
  margin-left: 3px;
  text-decoration: none;
}

.register-login__top-div {
  background-image: url("./assets/wavesBackground.png");
  height: 225px;
  padding: 15px 10px;
  padding-bottom: 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.register-login__top-div .header {
  color: white;
  font-weight: 700;
  font-size: 35px;
  margin-top: auto;
  margin-bottom: 70px;
}

.register-login__forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 20px;
  min-height: calc(100% - 225px);
  gap: 25px;
}

.register-login__forms .register-login__btn {
  background-color: var(--red);
  border: none;
  color: white;
  width: 100%;
  margin-top: auto;
  font-size: 15px;
  font-weight: 600;
  padding: 14px;
  border-radius: 100px;
  box-shadow: 0px 4px 8px 0px rgba(239, 54, 81, 0.35);
}

.register-login__forms a {
  color: black;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: -10px;
}

.register-login__forms .forgot-password {
  position: absolute;
  font-size: 15px;
  bottom: -25px;
  right: 0;
  font-weight: 500;
}

.locaro-input {
  background-color: white;
  cursor: pointer;
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
}

.locaro-input label {
  font-family: Montserrat;
  color: #818181;
  font-weight: 500;
}

.locaro-input fieldset {
  border: none;
}

.locaro-box-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.locaro-btn {
  background-color: var(--red);
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(239, 54, 81, 0.35);
}

.text-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.quantity-button {
  display: flex;
  align-items: center;
  background-color: #008000;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  gap: 5px;
}

.quantity-button svg {
  fill: white;
  height: 20px;
  width: 20px;
  padding: 5px;
  box-sizing: content-box;
}

.overlayDiv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  animation-duration: 500ms;
}

body #floating-message-container {
  position: fixed;
  top: 0;
  /* bottom: 0; */
  /* left: 0; */
  width: 100%;
  z-index: 9;
  max-width: 450px;
  height: auto;
  padding: 10px;
}

body #floating-message-container .floating-message {
  display: flex;
  border-radius: 12px;
  background-color: var(--darkgray);
  border: 2px solid rgba(141, 141, 141, 0.1);

  margin-bottom: 10px;
  color: White;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
}

body #floating-message-container .floating-message.error i {
  color: var(--red);
}

body #floating-message-container .floating-message.success i {
  color: #008000;
}

body #floating-message-container .floating-message.info i {
  color: #ff9900;
}

body #floating-message-container .floating-message i {
  flex-basis: 20%;
  padding: 16px 20px;
  font-size: 65px;
  text-align: center;
  align-self: flex-start;
}

body #floating-message-container .floating-message .msg-body {
  flex-basis: 78%;
  padding: 16px;
}

body #floating-message-container .floating-message .msg-body h3,
body #floating-message-container .floating-message .msg-body p {
  margin: 0;
}

body #floating-message-container .floating-message .msg-body h3 {
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 16px;
}

body #floating-message-container .floating-message .msg-body p {
  text-align: justify;
  font-size: 13px;
}

.pending {
  background-color: #ff9900;
}

.on-route {
  background-color: #50a8ff;
}

.declined {
  background-color: #b90504;
}

.accepted {
  background-color: #008000;
}
