.navbar {
  background-color: var(--black);
  padding: 15px 10px;
  position: sticky;
  top: 0;
  z-index: 8;
}

.navbar__top {
  display: flex;
  align-items: center;
}

.navbar__top a {
  margin-left: auto;
  height: 26px;
}

.navbar__top a svg {
  height: 26px;
  width: 26px;
  fill: white;
}

.navbar__search {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.navbar__search input {
  width: 100%;
  background-color: var(--darkgray);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 0px 30px 0px 40px;
  height: 40px;
  color: #616060;
  font-weight: 500;
}

.navbar__search input:focus {
  border: none;
  outline: none;
  color: white;
}

.navbar__search .search-icon {
  position: absolute;
  left: 10px;
  width: 18px;
  height: 18px;
}

.navbar__search .products {
  position: absolute;
  top: 45px;
  width: 100%;
  background-color: var(--darkgray);
  border-radius: 5px;
  padding: 10px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navbar__search .products .product-card {
  padding: 10px;
  color: var(--lightgray);
  border-bottom: 1px solid gray;
  text-decoration: none;
}

.navbar__back {
  margin-right: auto;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
}

.navbar__back svg {
  height: 25px;
  width: 25px;
}

.navbar__page-name {
  text-transform: capitalize;
  color: white;
  font-size: 18px;
  font-weight: 400;
}
