.categories-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 15px;
  padding-bottom: 90px;
}

.category-card-2 {
  border-radius: 15px;
}

.category-card-2 img {
  display: block;
  width: 100%;
  border-radius: 15px;
}
