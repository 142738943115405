.profile-container {
    margin-bottom: 75px;
    display: flex;
    flex-direction: column;
}

.profile-container > .header {
    font-weight: 700;
    font-size: 18px;
    padding: 20px 15px 10px 15px;
    /* margin: 10px 0px; */
}