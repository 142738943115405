.order-details {
  background-color: white;
}

.order-details__status {
  width: 100%;
  padding: 12px;
  font-weight: 600;
  color: white;
  /* background-color: grey; */
  display: flex;
  justify-content: center;
}

.order-details__info {
  padding: 10px;
}

.order-details__info h3 {
  font-size: 15px;
  margin-bottom: 5px;
}

.order-details__info h4 {
  font-weight: 500;
  margin-bottom: 5px;
}

.order-details__lower {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order-details__lower h5,
.order-details__lower h6 {
  font-size: 15px;
  font-weight: 500;
}

.order-details__paid-via {
  padding-top: 15px;
  font-weight: 600;
  padding-bottom: 5px;
  border-top: 3px solid var(--lightgray);
  display: flex;
  justify-content: space-between;
}

.order-details__note {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
  color: grey;
  font-weight: 300;
}
