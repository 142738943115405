.settings__top-div {
  background-image: url("../../assets/wavesBackground.png");
  height: 180px;
  padding: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.settings__name {
  font-size: 27px;
  color: white;
  font-weight: 800;
  margin-bottom: 5px;
  text-decoration: none;
}

.settings__email {
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-decoration: none;
}

.section {
  /* border-bottom: 2px solid rgb(212, 211, 211); */
  padding: 10px 15px 20px 15px;
  display: grid;
  grid-row-gap: 20px;
}

.section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section__header__text {
  font-weight: 700;
  font-size: 18px;
  color: #333333;
}

.section__header__button {
  background-color: var(--red);
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(239, 54, 81, 0.35);
}
