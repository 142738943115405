.verify-btn {
  font-weight: 700;
  color: #b45555;
  background-color: transparent;
  border: 2px solid #b45555;
  padding: 4px;
  border-radius: 6px;
  cursor: pointer;
}

.verified-btn {
  color: #55b455;
  border: 2px solid #55b455;
}

.otp-overlay-div {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 5;
  animation-duration: 500ms;
}

.otp-overlay-container {
  z-index: 6;
  height: 280px;
  width: 350px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.otp-top-message {
  color: #696363;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
}

.otp-inputs {
  display: flex;
  justify-content: center;
  padding: 18px;
}

.otp-input {
  width: 65px !important;
  margin-left: 7px;
  margin-right: 7px;
  height: 65px;
  border-radius: 7px;
  border: none;
  font-size: 25px;
  font-weight: 700;
  background-color: #d9d9d9;
  color: #696363;
}

.otp-overlay__resend {
  background-color: transparent;
  border: none;
  width: 150px;
  font-weight: 600;
  color: #8f8484;
}

.otp-overlay__verify {
  border: none;
  width: 170px;
  background-color: var(--red);
  border: none;
  color: white;
  margin-top: auto;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(239, 54, 81, 0.35);
  margin-bottom: 10px;
}

.otp-overlay__go-back {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  font-weight: 600;
  margin-right: 10px;
}
