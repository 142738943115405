.product-container {
  margin-bottom: 75px;
}

.product-container .image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  aspect-ratio: 5/4;
  background-color: white;
}

.product-container .image-div img {
  margin: 20px;
  max-width: 45%;
  max-height: 100%;
  aspect-ratio: auto;
}

.product-container .image-div .other-images-div {
  position: absolute;
  width: 100%;
  bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.product-container .image-div .other-images-div .other-image {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}

.product-container .product-info {
  background-color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
  padding: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
}

.product-container .product-info .product-name {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.product-container .product-info .subtext {
  color: gray;
  font-size: 15px;
}

.product-container .product-info .gray-div {
  background-color: #f0f0f0;
  color: #2f2c2c;
  text-decoration: none;
  padding: 10px;
  border-radius: 8px;
  margin: 15px 0 25px 0;
}

.product-container .product-info .gray-div:focus {
  outline: 0;
}

.product-container .product-info .gray-div .shop-name {
  font-weight: 600;
  font-size: 18px;
}

.product-container .product-info .gray-div .bottom-div {
  display: flex;
  margin-top: 20px;
}

.product-container .product-info .gray-div .bottom-div .price-div {
  display: flex;
  gap: 3px;
}

.product-container
  .product-info
  .gray-div
  .bottom-div
  .price-div
  .current-price {
  font-weight: 600;
  font-size: 18px;
}

.product-container .product-info .gray-div .bottom-div .price-div .old-price {
  text-decoration: line-through;
  color: gray;
  font-size: 15px;
}

.product-container
  .product-info
  .gray-div
  .bottom-div
  .price-div
  .percentage-div {
  border-radius: 5px;
  background-color: var(--red);
  color: white;
  padding: 5px 7px;
  font-size: 13px;
  font-weight: 500;
  height: fit-content;
  margin-left: 7px;
}

.product-container .product-info .gray-div .bottom-div .add-button {
  height: fit-content;
  margin-top: auto;
  background-color: #008000;
  color: white;
  font-weight: 600;
  border: none;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 7px;
  margin-left: auto;
}

.product-container .product-info .gray-div .bottom-div .quantity-button {
  margin-left: auto;
}

.product-container .product-info .description {
  font-size: 15px;
}
