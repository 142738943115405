.small-product-card {
  display: block;
  color: #2f2c2c;
  text-decoration: none;
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.small-product-card .image-div {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-product-card .image-div img {
  padding: 10px;
  max-width: 70%;
  max-height: 210px;
  object-fit: contain;
  aspect-ratio: auto;
}

.small-product-card .text-div {
  padding: 7px;
  display: flex;
  flex-direction: column;
  height: 110px;
}

.small-product-card .product-name {
  font-weight: 600;
  font-size: 14px;
}

.small-product-card .subtext {
  font-size: 14px;
  color: gray;
}

.small-product-card .bottom-div {
  display: flex;
  margin-top: auto;
}

.small-product-card .price-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.small-product-card .price-div .current-price {
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
}

.small-product-card .price-div .old-price {
  text-decoration: line-through;
  color: gray;
  font-size: 15px;
  line-height: 14px;
}

.small-product-card .add-button {
  height: fit-content;
  margin-top: auto;
  background-color: #008000;
  color: white;
  font-weight: 600;
  border: none;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 7px;
  margin-left: auto;
}

.small-product-card .quantity-button {
  margin-left: auto;
  border-radius: 7px;
}

.small-product-card .percentage-div {
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 7px;
  background-color: var(--red);
  color: white;
  padding: 5px 7px;
  font-size: 14px;
  font-weight: 500;
}
