.home-container {
  margin-bottom: 80px;
}

.categories-slider {
  margin-top: 5px;
  padding: 5px 10px;
  height: 100%;
}

.category-card {
  width: fit-content;
}

.category-card a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 12px;
  /* height: 100%; */
  /* padding: 0 15px; */
  text-decoration: none;
}

.category-card img {
  /* width: 100%; */
  border-radius: 12px;
  width: 120px;
  height: 100%;
}

.category-card .category-card__image-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.category-card .category-card__image-div img {
  width: 80%;
  position: absolute;
}

/* Promotion SLider */

.promotion-div {
  margin-top: 15px;
}

.promotion {
  width: 100%;
  padding: 0 20px;
}

.promotion .promotion__slide img {
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 4/3;
}

/* Shops Slider */

.shops-nearby {
  margin-top: 20px;
}

.shops-nearby__header {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
}

.shops-nearby__header h3 {
  font-weight: 700;
  color: #333;
  font-size: 17px;
}

.shops-nearby__header a {
  margin-left: auto;
  padding-right: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}

.shops-nearby__header a h4 {
  color: #333;
  font-weight: 500;
  padding-right: 3px;
}

.shops-nearby__header a .arrow-button {
  display: flex;
  align-items: center;
}

.shops-nearby__slider {
  margin-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: visible;
  width: 100%;
  height: 100%;
}

.shops-nearby__shop-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 10px;
  height: 100px;
  border-radius: 8px;
  color: white;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  text-decoration: none;
}

.shops-nearby__shop-card span {
  font-size: 13px;
  font-weight: 500;
  z-index: 1;
}

.shops-nearby__shop-card::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    #292929 0%,
    rgba(255, 255, 255, 0) 42.13%,
    rgba(217, 217, 217, 0) 100%
  );
}
