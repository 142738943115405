.cart-container {
  color: #2f2c2c;
  padding: 10px 0;
  margin-bottom: 75px;
  width: 100%;
}

.cart-container .filters {
  background-color: white;
  padding: 5px 0;
  margin-bottom: 15px;
}

.cart-container .filters__card {
  padding: 5px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.cart-container .filters__card .filters__select {
  background-color: var(--darkgray);
  color: white;
}
.cart-container .filters__card .filters__select fieldset {
  border: none;
}

.cart-container .filters__card .filters__select .MuiSelect-select {
  padding: 7px 14px;
  padding-right: 32px;
}

.cart-container .filters__card .filters__select .MuiSelect-icon {
  color: white;
}

.MuiPaper-root {
  background-color: var(--darkgray) !important;
  color: white !important;
}

.MuiPaper-root li {
  background-color: transparent !important;
}

.cart-container .cart__products {
  background-color: white;
  padding: 0px 10px 10px 10px;
}

.cart-container .cart__products .shop-div .shop-header {
  padding: 10px 0;
  border-bottom: 1px solid rgb(212, 211, 211);
}

.cart-container .cart__products .shop-div .shop-header span {
  display: block;
  padding: 10px;
  background-color: var(--lightgray);
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
}

.cart-product-card {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid rgb(212, 211, 211);
  height: 100px;
}

.cart-product-card .image-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  /* background-color: gray; */
  border-radius: 5px;
}

.cart-product-card .image-div img {
  max-height: 100%;
  aspect-ratio: auto;
}

.cart-product-card .text-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.cart-product-card .text-div .product-name {
  font-weight: 600;
  font-size: 14px;
}

.cart-product-card .bottom-div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: auto;
}

.cart-product-card .bottom-div .product-price {
  font-weight: 600;
}

.total {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 15px;
  padding: 10px 10px;
}

.item-total,
.handling-charges,
.delivery-charges,
.to-pay {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 5px;
  /* padding-right: 10px; */
  margin-top: auto;
}

.item-total__text,
.item-total__price {
  font-size: 17px;
  font-weight: 600;
}

.handling-charges__text,
.handling-charges__price {
  font-size: 13px;
  font-weight: 500;
  color: grey;
}

.delivery-charges__text,
.delivery-charges__price {
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 5px;
  color: grey;
}

.to-pay {
  padding-top: 10px;
  border-top: 3px solid var(--lightgray);
}

.to-pay__text,
.to-pay__price {
  font-size: 18px;
  font-weight: 700;
}

.saved-bar {
  background-color: var(--red);
  text-align: center;
  padding: 8px;
  font-size: 15px;
  font-weight: 500;
  color: white;
  margin-bottom: 70px;
}

.complete-payment {
  background-color: var(--darkgray);
  color: white;
  position: fixed;
  bottom: 70px;
  padding: 15px 20px;
  width: 100%;
  max-width: 450px;
  height: 70px;
  display: flex;
  justify-content: space-between;
}

.complete-payment__text-section {
  height: 100%;
  display: flex;
  margin-top: -3px;
  flex-direction: column;
  justify-content: center;
}

.complete-payment__to-pay {
  font-size: 13px;
}

.complete-payment__price {
  font-size: 19px;
  font-weight: 600;
  padding-top: 2px;
}

.complete-payment__button {
  background-color: #008000;
  width: 230px;
  border: none;
  color: white;
  margin-top: auto;
  font-size: 15px;
  font-weight: 600;
  padding: 14px;
  border-radius: 7px;
  margin-top: -5px;
}

.empty-cart-container {
  width: 100%;
  height: 100%;
  margin-top: -56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-cart-container img {
  width: 230px;
  margin-bottom: 30px;
}

.empty-cart-text {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 270px;
  font-size: 14px;
  font-weight: 600;
  color: #818181;
  text-align: center;
}
