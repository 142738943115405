.category-container {
    padding: 10px;
    margin-bottom: 80px;
}

.category-container .category-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.category-container .show-more {
    width: 100%;
    background-color: white;
    border: none;
    padding: 12px;
    margin-top: 15px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--red);
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.3);
}