.shop-container {
  margin-bottom: 85px;
}

.shop-container .shop-image {
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: center;
  background-color: gray;
}

.shop-container .shop-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.shop-container .shop-details .shop-name {
  font-size: 18px;
}

.shop-container .shop-details .shop-location {
  font-size: 15px;
}

.shop-container .shop-products {
  padding: 10px;
}

.shop-container .shop-products h1 {
  font-size: 19px;
  margin-bottom: 15px;
}

.shop-container .shop-products .show-more {
  width: 100%;
  background-color: white;
  border: none;
  padding: 12px;
  margin-top: 15px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  color: var(--red);
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.shop-container .shop-products .products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
