.profile-button {
  background-color: white;
  /* text-decoration: none; */
  color: #2f2c2c;
  display: flex;
  height: 80px;
  padding: 15px;
  align-items: center;
  margin-bottom: 5px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.profile-button .icon {
  height: 100%;
  aspect-ratio: 1;
  background-color: var(--red);
  padding: 8px;
  border-radius: 8px;
}

.profile-button .text {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  justify-content: center;
  align-items: baseline;
  height: 100%;
}

.profile-button .text .header {
  font-weight: 600;
}

.profile-button .text .subtext {
  color: gray;
  font-size: 14px;
  padding-top: 6px;
}

.profile-button .arrow-button {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
}
