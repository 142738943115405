.tab-bar {
    position: fixed;
    bottom: 0;
    left: 50%;
    max-width: 450px;
    width: 100%;
    transform: translateX(-50%);
    z-index: 10;
}

.tab-bar .MuiBottomNavigation-root {
    background-color: var(--black);
    height: 75px;
    border-radius: 5px 5px 0 0;
}

.tab-bar .MuiButtonBase-root {
    color: var(--lightgray);
    gap: 3px;
}


.tab-bar .Mui-selected {
    color: var(--red);
}